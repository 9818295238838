<template>
  <f7-page class="profile-page">
    <template #fixed>
      <ProfileNavigationComponent type="back" :title="$t.getTranslation('LBL_ORDER')" :settings="false" :f7router="f7router" />
    </template>

    <section class="profile-container">
      <div class="container">
        <div class="profile-content-container">
          <template v-if="orderList && orderList.length > 0">
            <div v-for="order in orderList" :key="'ord_' + order.OrderKey">
              <OrderItemCardComponent :data="order" />
            </div>
          </template>

          <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_ORDER_EMPTY')" />
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent } from 'vue'
import { get } from '@/utils/axios'

import { useStore } from "@/store";

// import ProfileNavigationComponent from '@/components/navigations/ProfileNavigationComponent.vue'
// import OrderItemCardComponent from '@/components/cards/OrderItemCardComponent.vue'
// import NoDataFoundComponent from '@/components/NoDataFoundComponent.vue'

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));
const OrderItemCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "order-item-card" */ /* webpackMode: "lazy" */ "@/components/cards/OrderItemCardComponent.vue"));

export default defineComponent({
  name: 'ProfileOrderPage',
  components: {
    ProfileNavigationComponent,
    OrderItemCardComponent,
    NoDataFoundComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const orderList = ref([])
    const orderMode = ref('all')

    const store = useStore();
    
    const getOrders = async () => {
      let obj = {
        filter: orderMode.value == 'all' ? '' : orderMode.value
      }

      if (props.f7route.query.ParentOrderKey) {
        obj = {
          ParentOrderKey: props.f7route.query.ParentOrderKey
        }
      }

      let ret = await get('/order/list', obj)
      orderList.value = ret?.List
    }

    onMounted(() => {
      if (props?.f7route?.query?.mode) {
        orderMode.value = props?.f7route?.query?.mode
      }

      getOrders()
    })

    return {
      orderList,
      orderMode
    }
  }
})
</script>
